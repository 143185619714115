* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, input, button, label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
    text-align: center;
    color: #333;
}


.App-header {
    background-color: #639A00; 
    color: white;
    padding: 10px 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#logo {
    height: 30px;
    margin-right: 20px;
    border: 3px solid white;
}


#upload-area {
    background-color: #e5e5e5; 
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    max-width: 500px;
}

#upload-area input,
#upload-area input[type="file"],
#upload-area input[type="text"],
#upload-area input[type="email"],
#upload-area button {
    width: 100%;
    padding: 10px;
    margin: 5px 0; 
    border-radius: 5px;
    border: 2px solid #ddd;
}

#upload-area label {
    display: block;
    margin-right: 20px;
}

#upload-button {
    background-color: #5b8a3c; 
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

#upload-button:hover {
    background-color: #4b6c2c;
}


#status-area, #processing-area, #upload-status {
    margin: 10px auto;
    padding: 20px;
    max-width: 500px;
}

#status-area, #processing-area {
    background-color: #f0f0f0; 
    border-radius: 10px;
}

#upload-status p {
    color: #639A00; 
}

#upload-status p.error {
    color: #d9534f; 
}


.checkbox-container,
.select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.checkbox-container label,
.select-container label {
    min-width: 150px;
    text-align: left;
}

.checkbox-container input,
.select-container select {
    flex-grow: 1;
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: 2px solid #ddd;
}


.checkbox-custom {
    appearance: none;
    background-color: #ffffff;
    border: 2px solid #cacece;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    outline: none;
    margin-left: 10px;
}


.checkbox-custom:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 11px;
    border: solid #639A00;
    border-width: 0 2px 2px 0;
    
    transform: translate(-50%, -50%) rotate(45deg);
}

.error {
    color: red;
}

.success {
    color: green;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner {
    border: 4px solid rgba(0,0,0,.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
